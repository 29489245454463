
















import { Component, Mixins } from 'vue-property-decorator'

import { ShippingMethod } from '../../../../contexts'

import { AbstractStepPreview } from '../AbstractStepPreview'
import { getShipmentName, shippingIcon } from '../Shippings/Shippings.helpers'
import StructureConfigurable from '../../../../support/mixins/StructureConfigurable.mixin.vue'
import { SHIPPING_STEP_COMPONENT_KEY } from './ShippingStep.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ShippingStepPreview>({
  name: 'ShippingStepPreview',
  created (): void {
    this.config = this.getComponentConfig(SHIPPING_STEP_COMPONENT_KEY, { buttonGoNextTheme: 'secondary' })
  }
})
export class ShippingStepPreview extends Mixins<AbstractStepPreview, StructureConfigurable>(AbstractStepPreview, StructureConfigurable) {
  private method: ShippingMethod | null = this.payload?.shipping ?? null

  public get icon (): string | null {
    if (!this.method) {
      return null
    }

    return shippingIcon[this.method.methodCode]
  }

  public get name (): string | null {
    if (!this.method) {
      return null
    }

    return getShipmentName(this.$t, this.method, !this.useMagentoLabels)
  }

  public get price (): number {
    return this.method ? this.method.price.value : 0
  }

  public get useMagentoLabels (): boolean {
    return this.getConfigProperty<boolean>('useMagentoLabels') ?? false
  }
}

export default ShippingStepPreview
