



























































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { logger } from '../../../support'
import {
  OrderData,
  OrderResponse,
  PaymentMethodCode
} from '../../../contexts'

import { FullscreenLoader } from '../../shared/molecules/Loader/FullscreenLoader.vue'
import { SidesLayout } from '../../shared/molecules/SidesLayout'
import { BuyerContextSwitch } from '../organisms/BuyerContextSwitch'
import { CheckoutStepConstructor } from '../contracts'
import { ORDER_KEY } from '../services/cart'
import { CustomerDetailsStep, CustomerDetailsStepPreview } from '../organisms/CustomerDetailsStep'
import { PaymentStep, PaymentStepPreview } from '../organisms/PaymentStep'
import { RouteName } from '../routes'
import { ShippingStep, ShippingStepPreview } from '../organisms/ShippingStep'
import { SingleCartItem } from '../organisms/SingleCartItem'
import { Step } from '../molecules/Step'
import {
  translateCartToCheckoutPayload,
  translateCheckoutPayloadToValidationObject
} from '../helpers/checkout.helpers'
import { translateToCartItem } from '../molecules/CartItem/CartItem.helpers'
import { CheckoutBase } from './base'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<CheckoutView>({
  name: 'CheckoutView',
  components: {
    BuyerContextSwitch,
    CustomerDetailsStep,
    FullscreenLoader,
    PaymentStep,
    PaymentStepPreview,
    ShippingStep,
    ShippingStepPreview,
    SidesLayout,
    SingleCartItem,
    Step
  },
  mounted () {
    this.pages = this.siteService?.getActiveSiteUrls()
    this.verifyIfStepSet()

    if (!this.cartLoading) {
      this.updateCheckoutFromCart()
      this.validateSteps()
    }
  }
})
export class CheckoutView extends Mixins<CheckoutBase>(CheckoutBase) {
  public readonly previews: VueConstructor[] = [
    CustomerDetailsStepPreview,
    ShippingStepPreview,
    PaymentStepPreview
  ]

  public readonly steps: CheckoutStepConstructor[] = [
    CustomerDetailsStep,
    ShippingStep,
    PaymentStep
  ]

  public get allAgreementsChecked (): boolean {
    return this.agreements.includes('privacy') && this.agreements.includes('newsletter')
  }

  public set allAgreementsChecked (value: boolean) {
    this.agreements = value ? ['privacy', 'newsletter'] : []
  }

  public get isSubmitDisabled (): boolean {
    if (this.checkoutPayload) {
      const lastStep = this.steps[this.steps.length - 1]

      if (lastStep.isValidStep) {
        return !lastStep.isValidStep(
          translateCheckoutPayloadToValidationObject(this.checkoutPayload),
          this.$t.bind(this),
          this.$i18n.locale
        )
      }
    }

    return true
  }

  public async placeOrder (): Promise<void> {
    if (!this.cart?.isSet()) {
      return
    }

    if (!this.agreements.includes('privacy')) {
      this.showAgreementWarning = true
      return
    }

    this.placingOrder = true

    try {
      const response = await this.checkoutService.placeOrder(this.cart.id)
      await this.afterOrderPlaced(response)
    } catch (e) {
      logger(e, 'warn')
      this.globalError = (e as Error).message
    }

    this.placingOrder = false
  }

  public setWarning (value: boolean): void {
    if (value) {
      this.showAgreementWarning = false
    }
  }

  public translateToCartItem = translateToCartItem

  protected async afterOrderPlaced (order: OrderResponse): Promise<void> {
    try {
      if (this.agreements.includes('newsletter')) {
        if (this.checkoutPayload?.user.email) {
          this.eventBus.emit('app:newsletter.subscribe', {
            email: this.checkoutPayload?.user.email, name: ''
          })
        }
      }
    } catch (e) {
      logger(e, 'warn')
    }

    try {
      await this.saveOrderData(order)

      this.isSubmitted = true
      await this.handlePayment(order)

      this.$nextTick(() => this.deleteCart())
    } catch (e) {
      logger(e, 'warn')
    }
  }

  protected async saveOrderData (order: OrderResponse): Promise<void> {
    if (!this.cart || !this.cart.selectedShippingMethod) {
      throw new Error('Missing or invaild cart')
    }
    // ,
    //   cart: this.cart,
    //     deliveryTime: this.deliveryTime,
    //     applied_coupon_code: this.currentCouponCode ? this.currentCouponCode.code : null
    const orderData: OrderData = {
      ...order,
      cart: this.cart,
      ...this.cart.selectedShippingMethod.deliveryTime,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      total: this.$options.filters!.currency(this.cart.getTotalPrice())
    }

    localStorage.setItem(ORDER_KEY, JSON.stringify(orderData))
  }

  protected async handlePayment (order: OrderResponse) {
    const redirectToThankYouPage = async () => {
      this.placingOrder = false
      return await this.$router.push({ name: `checkout.${RouteName.ThankYou}` })
    }

    const redirectToErrorPage = async (code: string) => {
      this.placingOrder = false
      return await this.$router.push({ name: `checkout.${RouteName.ThankYou}`, params: { code } })
    }

    const redirectToGateway = async (url?: string) => {
      if (!url) {
        return redirectToThankYouPage()
      }

      return window.location.assign(url)
    }
    if (!order.paymentMethod) {
      return await redirectToThankYouPage()
    }

    switch (order.paymentMethod) {
      // todo: pozostale metody platnosci
      case PaymentMethodCode.PayU: {
        if (!order.payuRedirectUri) {
          throw new Error('Missing required payuRedirectUri!')
        }

        return await redirectToGateway(order.payuRedirectUri)
      }
      case PaymentMethodCode.PayNow: {
        if (!order.paynowRedirectUri) {
          throw new Error('Missing required paynowRedirectUri!')
        }

        return await redirectToGateway(order.paynowRedirectUri)
      }
      case PaymentMethodCode.Ngenius: {
        if (!order.ngeniusPaymentUrl) {
          throw new Error('Missing required ngeniusPaymentUrl!')
        }

        return await redirectToGateway(order.ngeniusPaymentUrl)
      }
      case PaymentMethodCode.Blik:
        try {
          alert('blik') // todo: blik
          // await openBlikModal();

          return await redirectToThankYouPage()
        } catch (e) {
          return await redirectToErrorPage(e as string) // todo
        }
      default:
        return await redirectToThankYouPage()
    }
  }

  @Watch('cart')
  protected initCheckout () {
    this.updateCheckoutFromCart()
    this.validateSteps()
  }

  protected validateSteps (): void {
    if (this.isSubmitted) {
      return
    }

    const stepCandidate: number = parseInt(String(this.$route.query.step))
    if (stepCandidate < 1 || stepCandidate > 3) {
      this.moveToStep(1, true)
      return
    }

    for (let i = 0; i < (stepCandidate - 1); i++) {
      if (typeof this.steps[i].isValidStep !== 'function') {
        continue
      }

      if (
        this.checkoutPayload === null ||
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        !this.steps[i].isValidStep!(
          translateCheckoutPayloadToValidationObject(this.checkoutPayload),
          this.$t.bind(this),
          this.$i18n.locale
        )
      ) {
        this.moveToStep(i + 1)
        return
      }
    }
  }

  protected updateCheckoutFromCart (): void {
    const translatedCart = translateCartToCheckoutPayload(this.cart)

    if (!translatedCart) {
      if (!this.cartLoading && !this.isWaitingForAuth) {
        this.$router.push({ name: `checkout.${RouteName.Cart}` })
      }

      return
    }

    this.checkoutPayload = {
      ...this.checkoutPayload,
      ...translatedCart,
      acceptPrivacy: this.agreements.includes('privacy'),
      isSignupRequested: this.checkoutPayload?.isSignupRequested ?? false,
      isUserLoggedIn: this.checkoutPayload?.isUserLoggedIn ?? false
    }
  }
}

export default CheckoutView
