


















import { AnyObject, EventbusType, IEventbus } from '@movecloser/front-core'
import { Component, Mixins, Watch } from 'vue-property-decorator'

import {
  CartItemData,
  ISiteService,
  ShippingMethod,
  SiteServiceType
} from '../../../../contexts'

import { Inject, logger } from '../../../../support'

import { AbstractStep } from '../AbstractStep'
import { CartMutationTypes, CheckoutStepCallback } from '../../contracts'

import StructureConfigurable from '../../../../support/mixins/StructureConfigurable.mixin.vue'

import { SHIPPING_STEP_COMPONENT_KEY } from './ShippingStep.contracts'
import { Shippings } from '../Shippings'
import { supportedShippingMethods } from '../Shippings/Shippings.helpers'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ShippingStep>({
  name: 'ShippingStep',
  components: {
    Shippings
  },
  created (): void {
    this.config = this.getComponentConfig(SHIPPING_STEP_COMPONENT_KEY, { buttonGoNextTheme: 'secondary' })
  }
})
export class ShippingStep extends Mixins<AbstractStep, StructureConfigurable>(AbstractStep, StructureConfigurable) {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  public selectedShipping: ShippingMethod | null = this.payload.shipping

  public callbacks: CheckoutStepCallback[] = []

  public get isDisabled (): boolean {
    return !this.isValidStep()
  }

  public get availableShippingMethods (): string[] {
    return this.getConfigProperty<string[]>('drivers') ?? supportedShippingMethods
  }

  public get useMagentoLabels (): boolean {
    return this.getConfigProperty<boolean>('useMagentoLabels') ?? false
  }

  public static isValidStep (payload: AnyObject): boolean {
    return !!payload.shipping
  }

  @Watch('selectedShipping')
  public selectShipping (value: string): void {
    this.onChange('shipping', value)
  }

  public async submit (): Promise<void> {
    if (!this.selectedShipping) {
      return
    }

    try {
      this.$emit('saving', true)
      let cart = await this.checkoutService.setShippingMethod(this.cart.id, this.selectedShipping)
      for (const c of this.callbacks) {
        const returned = await c.callable.apply(c.onThis, c.args)
        if (typeof returned === 'object' && returned !== null) {
          cart = returned
        }
      }

      this.$store.commit(CartMutationTypes.SetCart, cart)

      const getFirstVariant = (item: CartItemData) => {
        return Object.values(item.product.variants)[0]
      }
      const getFirstVariantKey = (item: CartItemData) => {
        const key = Object.keys(item.product.variants)[0] ?? ''
        return (key !== '_') ? key : ''
      }
      this.eventBus.emit(
        'app:checkout.addShippingInfo',
        {
          currency: this.cart.total.currency,
          value: this.cart.total.value,
          coupon: (this.cart.coupons[0]) ? this.cart.coupons[0].code : '',
          shippingTier: this.cart.selectedShippingMethod?.methodCode,
          items: this.cart.items.map((item) => {
            const variant = getFirstVariant(item)
            const variantSlug = getFirstVariantKey(item)
            return {
              id: variant.sku,
              parent_id: item.product.sku,
              variant: variantSlug,
              name: variant.name,
              category: variant.attributes.mainCategory,
              brand: variant.attributes.brand,
              currency: this.cart.total.currency,
              price: variant.price.finalPrice,
              quantity: item.quantity,
              url: this.siteService.getActiveSiteAddress() + `${item.product.urlPath}`,
              image: item.product.variants && (Array.isArray(variant.images) &&
                variant.images.length > 0)
                ? this.siteService.getActiveSiteAddress() +
                variant.images[0].url : ''
            }
          })
        }
      )

      this.nextStep()
    } catch (e) {
      logger(e, 'warn')
      this.showToast((e as Error).message, 'warning')
    } finally {
      this.$emit('saving', false)
    }
  }

  protected isValidStep (): boolean {
    return !!this.selectedShipping
  }
}

export default ShippingStep
